//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import jstz from 'jstimezonedetect';
import LoginButton from './LoginButton';
import { generateUrl, imagePath } from '@nextcloud/router';
export default {
  name: 'LoginForm',
  components: {
    LoginButton: LoginButton
  },
  props: {
    username: {
      type: String,
      default: ''
    },
    redirectUrl: {
      type: [String, Boolean],
      default: false
    },
    errors: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    messages: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    throttleDelay: {
      type: Number,
      default: 0
    },
    invertedColors: {
      type: Boolean,
      default: false
    },
    autoCompleteAllowed: {
      type: Boolean,
      default: true
    },
    directLogin: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      loading: false,
      timezone: jstz.determine().name(),
      timezoneOffset: -new Date().getTimezoneOffset() / 60,
      user: this.username,
      password: '',
      passwordInputType: 'password'
    };
  },
  computed: {
    apacheAuthFailed: function apacheAuthFailed() {
      return this.errors.indexOf('apacheAuthFailed') !== -1;
    },
    internalException: function internalException() {
      return this.errors.indexOf('internalexception') !== -1;
    },
    invalidPassword: function invalidPassword() {
      return this.errors.indexOf('invalidpassword') !== -1;
    },
    userDisabled: function userDisabled() {
      return this.errors.indexOf('userdisabled') !== -1;
    },
    toggleIcon: function toggleIcon() {
      return imagePath('core', 'actions/toggle.svg');
    },
    loadingIcon: function loadingIcon() {
      return imagePath('core', 'loading-dark.gif');
    },
    loginActionUrl: function loginActionUrl() {
      return generateUrl('login');
    }
  },
  mounted: function mounted() {
    if (this.username === '') {
      this.$refs.user.focus();
    } else {
      this.$refs.password.focus();
    }
  },
  methods: {
    togglePassword: function togglePassword() {
      if (this.passwordInputType === 'password') {
        this.passwordInputType = 'text';
      } else {
        this.passwordInputType = 'password';
      }
    },
    updateUsername: function updateUsername() {
      this.$emit('update:username', this.user);
    },
    submit: function submit() {
      this.loading = true;
      this.$emit('submit');
    }
  }
};