//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from '@nextcloud/axios';
import { generateUrl } from '@nextcloud/router';
export default {
  name: 'ResetPassword',
  props: {
    username: {
      type: String,
      required: true
    },
    resetPasswordLink: {
      type: String,
      required: true
    },
    invertedColors: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      error: false,
      loading: false,
      message: undefined,
      user: this.username
    };
  },
  watch: {
    username: function username(value) {
      this.user = value;
    }
  },
  methods: {
    updateUsername: function updateUsername() {
      this.$emit('update:username', this.user);
    },
    submit: function submit() {
      var _this = this;

      this.loading = true;
      this.error = false;
      this.message = '';
      var url = generateUrl('/lostpassword/email');
      var data = {
        user: this.user
      };
      return axios.post(url, data).then(function (resp) {
        return resp.data;
      }).then(function (data) {
        if (data.status !== 'success') {
          throw new Error("got status ".concat(data.status));
        }

        _this.message = 'send-success';
      }).catch(function (e) {
        console.error('could not send reset email request', e);
        _this.error = true;
        _this.message = 'send-error';
      }).then(function () {
        _this.loading = false;
      });
    }
  }
};