var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "loginForm",
      attrs: { method: "post", name: "login", action: _vm.loginActionUrl },
      on: { submit: _vm.submit }
    },
    [
      _c(
        "fieldset",
        [
          _vm.apacheAuthFailed
            ? _c("div", { staticClass: "warning" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.t("core", "Server side authentication failed!"))
                ),
                _c("br"),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    _vm._s(
                      _vm.t("core", "Please contact your administrator.")
                    ) + "\n\t\t\t"
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.messages, function(message, index) {
            return _c("div", { key: index, staticClass: "warning" }, [
              _vm._v("\n\t\t\t" + _vm._s(message)),
              _c("br")
            ])
          }),
          _vm._v(" "),
          _vm.internalException
            ? _c("div", { staticClass: "warning" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.t("core", "An internal error occurred."))
                ),
                _c("br"),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    _vm._s(
                      _vm.t(
                        "core",
                        "Please try again or contact your administrator."
                      )
                    ) + "\n\t\t\t"
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "hidden", attrs: { id: "message" } }, [
            _c("img", {
              staticClass: "float-spinner",
              attrs: { alt: "", src: _vm.loadingIcon }
            }),
            _vm._v(" "),
            _c("span", { attrs: { id: "messageText" } }),
            _vm._v(" "),
            _c("div", { staticStyle: { clear: "both" } })
          ]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "grouptop", class: { shake: _vm.invalidPassword } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user,
                    expression: "user"
                  }
                ],
                ref: "user",
                staticClass: "login",
                attrs: {
                  id: "user",
                  type: "text",
                  name: "user",
                  autocapitalize: "none",
                  autocorrect: "off",
                  autocomplete: _vm.autoCompleteAllowed ? "on" : "off",
                  placeholder: _vm.t("core", "Username or email"),
                  "aria-label": _vm.t("core", "Username or email"),
                  required: ""
                },
                domProps: { value: _vm.user },
                on: {
                  change: _vm.updateUsername,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.user = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { staticClass: "infield", attrs: { for: "user" } }, [
                _vm._v(_vm._s(_vm.t("core", "Username or email")))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "groupbottom",
              class: { shake: _vm.invalidPassword }
            },
            [
              _c("input", {
                ref: "password",
                staticClass: "password-with-toggle login-password",
                attrs: {
                  id: "password",
                  type: _vm.passwordInputType,
                  name: "password",
                  autocorrect: "off",
                  autocapitalize: "none",
                  autocomplete: _vm.autoCompleteAllowed
                    ? "current-password"
                    : "off",
                  placeholder: _vm.t("core", "Password"),
                  "aria-label": _vm.t("core", "Password"),
                  required: ""
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "infield", attrs: { for: "password" } },
                [_vm._v(_vm._s(_vm.t("Password")))]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "toggle-password toggle-pass",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.togglePassword.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.toggleIcon,
                      alt: _vm.t("core", "Toggle password visibility")
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("LoginButton", {
            attrs: {
              loading: _vm.loading,
              "inverted-colors": _vm.invertedColors
            }
          }),
          _vm._v(" "),
          _vm.invalidPassword
            ? _c("p", { staticClass: "warning wrongPasswordMsg" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.t("core", "Wrong username or password.")) +
                    "\n\t\t"
                )
              ])
            : _vm.userDisabled
            ? _c("p", { staticClass: "warning userDisabledMsg" }, [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.t("core", "User disabled")) + "\n\t\t"
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.throttleDelay && _vm.throttleDelay > 5000
            ? _c("p", { staticClass: "warning throttledMsg" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm.t(
                        "core",
                        "We have detected multiple invalid login attempts from your IP. Therefore your next login is throttled up to 30 seconds."
                      )
                    ) +
                    "\n\t\t"
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.redirectUrl
            ? _c("input", {
                attrs: { type: "hidden", name: "redirect_url" },
                domProps: { value: _vm.redirectUrl }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "timezone" },
            domProps: { value: _vm.timezone }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "timezone_offset" },
            domProps: { value: _vm.timezoneOffset }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "requesttoken" },
            domProps: { value: _vm.OC.requestToken }
          }),
          _vm._v(" "),
          _vm.directLogin
            ? _c("input", {
                attrs: { type: "hidden", name: "direct", value: "1" }
              })
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }